import {useEffect} from 'react';

import useToggle from './useToggle';
import usePrevious from './usePrevious';

interface MenuToggleOptions {
  onToggle?: () => void;
}

export default function useMenuToggle({onToggle = () => {}}: MenuToggleOptions) {
  const [isMenuOpen, toggleIsMenuOpen] = useToggle(false);
  const previousIsMenuOpen = usePrevious(isMenuOpen);

  useEffect(() => {
    if (previousIsMenuOpen && !isMenuOpen) {
      document.querySelector('[data-name="offer-results"]')?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [isMenuOpen, previousIsMenuOpen]);

  const toggleMenu = () => {
    toggleIsMenuOpen();
    onToggle();
    document.body.style.overflow = isMenuOpen === false ? 'hidden' : '';
  };

  return {
    toggleMenu,
    isMenuOpen,
  };
}
