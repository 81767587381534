export const registerInteraction = (eventAction: string, eventCategory: string, eventLabel: string) => {
  pushToDataLayer({
    event: 'ga-dataLayer',
    eventAction,
    eventCategory,
    eventLabel,
    nonInteraction: false,
  });
};

export const registerView = (eventAction: string, eventLabel: string) => {
  pushToDataLayer({
    event: 'ga-dataLayer',
    eventAction,
    eventCategory: 'vertoningen',
    eventLabel,
    nonInteraction: true,
  });
};

const pushToDataLayer = (event: DataLayerEvent) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};

declare global {
  interface Window {
 dataLayer?: any[];
}
}

interface DataLayerEvent {
  event: string;
  eventAction: string;
  eventCategory: string;
  eventLabel: string;
  nonInteraction: boolean;
}
