import {MouseEventHandler, ReactElement} from 'react';

import {BaseFilter, FilterOption} from '../../advisor-config/utils';
import {SelectedFilters} from '../../types/SelectedFilters';

import Filter from './Filter';

export type FiltersRecord = Record<string, BaseFilter>;

interface FiltersProps {
  isMenuOpen: boolean;
  toggleMenu: MouseEventHandler;
  filters: FiltersRecord;
  setFilterOption: (filter: BaseFilter, option: FilterOption) => void;
  selectedFilters: SelectedFilters;
  resetFilters: MouseEventHandler;
  OrderBy?: () => ReactElement;
  hasNonFeaturedFilters: boolean;
  hasFeaturedFilters: boolean;
}

export default function Filters({
  isMenuOpen,
  toggleMenu,
  filters,
  selectedFilters,
  setFilterOption,
  resetFilters,
  OrderBy,
  hasNonFeaturedFilters,
  hasFeaturedFilters,
}: FiltersProps) {

  return (
    <div className={`${hasNonFeaturedFilters || hasFeaturedFilters ? '' : 'sf-hidden'} lg:sf-px-0 sf-max-w-sm`} data-name="filters">
      <div className={`${isMenuOpen ? 'sf-block sf-fixed sf-top-0 sf-left-0 sf-bg-white sf-z-[100] sf-w-full' : 'sf-hidden'} lg:sf-block`}>
        <div className={`${hasNonFeaturedFilters ? '' : 'lg:sf-hidden'} sf-sticky lg:sf-relative sf-top-0 sf-w-full sf-bg-white sf-z-10`}>
          <div className="sf-pt-4 sf-font-bold sf-text-center lg:sf-text-left lg:sf-font-normal lg:sf-text-gray-400 sf-mb-4">Filters</div>
          <button type="reset" className="sf-absolute sf-right-0 sf-top-0 sf-text-md sf-p-4 sf-text-primary sf-hidden lg:sf-block" onClick={resetFilters}>Reset</button>
          <div className="sf-absolute sf-right-0 sf-top-0 sf-pr-4 sf-pt-4 sf-text-lg sf-cursor-pointer lg:sf-hidden" onClick={toggleMenu}>&times;</div>
          <div className="sf-w-full sf-border-b sf-border-gray-200" />
        </div>
        {OrderBy && <div className="sf-pt-4" data-name="filters__order-by"><OrderBy /></div>}
        <div className="sf-space-y-8 sf-flex sf-flex-col sf-p-4 lg:sf-px-0 sf-overflow-scroll lg:sf-overflow-hidden sf-h-screen lg:sf-h-auto sf-pb-40 lg:sf-pb-4">
          {Object.entries(filters).map(([identifier, filter]) => {
            if ((selectedFilters[identifier] || []).length === 0 && filter.options?.length === 1) {
              return null;
            }
            return (
              <div key={identifier} className={`${filter.featured ? 'lg:sf-hidden' : ''}`}>
                <Filter
                  filter={filter}
                  selectedOptions={selectedFilters[identifier] || []}
                  handleOnChange={setFilterOption}
                />
              </div>
            );
          })}
          <button type="reset" className={`${hasNonFeaturedFilters ? '' : 'lg:sf-hidden'} sf-text-lg sf-p-4 sf-text-primary`} onClick={resetFilters}>Reset</button>
        </div>
        <div className="sf-flex sf-flex-col sf-w-full sf-sticky lg:sf-relative sf-bottom-0 sf-bg-white">
          <div className="sf-w-full sf-border-t sf-mb-2 lg:sf-hidden" />
          <div className="sf-px-4 lg:sf-px-0">
            <button type="button" onClick={toggleMenu} className="sf-z-90 sf-rounded-md sf-text-white sf-text-center sf-font-bold sf-text-lg sf-bg-primary hover:sf-bg-primary/80 sf-px-4 sf-py-2 sf-w-full sf-mb-2 lg:sf-hidden">Bekijk resultaten</button>
          </div>
        </div>
      </div>
    </div>
  );
}
