/* eslint-disable id-length */
export default function sortBy(array: any[], key: string | number) {
  return array.sort(
    (a: any, b: any) => {
      const x = a[key];
      const y = b[key];
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    },
  );
}
