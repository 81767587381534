export default function Euro({amount}: {amount: number;}) {
  return (
    <>
      {new Intl.NumberFormat(`nl-NL`, {
        currency: `EUR`,
        style: 'currency',
      }).format(amount)}
    </>
  );
}
