import {useLazyQuery} from '@apollo/client';
import {useEffect, useState} from 'react';

import {productFiltersToPackageFilters} from '../advisor-config/package/smartphones';
import {
  AdvisorConfig,
  BaseFilter,
  ControlType,
  EMPTY_DEFAULT_IDENTIFIER,
  facetsToFilters,
  FilterOption,
  FilterType,
} from '../advisor-config/utils';
import {FiltersRecord} from '../components/filters/Filters';
import {
  PackageFacets,
  PackageOrderBy,
  PackageSearch,
  PlanFacets,
  PlanSearch,
  ProductFacets,
  ProductSearch,
} from '../generated/graphql';
import {SelectedFilters} from '../types/SelectedFilters';

import useHistory from './useHistory';
import usePrevious from './usePrevious';

type Search = ProductSearch | PackageSearch | PlanSearch;
type Facets = ProductFacets | PackageFacets | PlanFacets;

export default function useSearch(
  queryDocument: any,
  filterConfig: any,
  initialResults: Search,
  initialFilters: SelectedFilters,
  platformName: string,
  defaultFilters: SelectedFilters,
  initialPackageSearch?: PackageSearch,
) {
  const [isInitialized, setIsInitialized] = useState(false);
  const [orderBy, setOrderBy] = useState<PackageOrderBy>(PackageOrderBy.TOTAL_PRICE_PER_PROVIDER);
  const [results, setResults] = useState(initialResults);
  const [url, setUrl] = useState<string>('');
  const [packageUrl, setPackageUrl] = useState(initialPackageSearch && initialPackageSearch.results && initialPackageSearch.results.count && initialPackageSearch.results.count > 0 ? initialPackageSearch.url : null);
  const [filters, setFilters] = useState<FiltersRecord>(facetsToFilters(filterConfig, initialResults.facets as Facets));
  const [first, setFirst] = useState(10);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>(initialFilters);
  const previousSelectedFilters = usePrevious(selectedFilters);
  const previousFirst = usePrevious(first);
  const previousOrderBy = usePrevious(orderBy);
  const [hasFeaturedFilters, setHasFeaturedFilters] = useState<boolean>(_hasFeaturedFilters(filters));
  const [hasNonFeaturedFilters, setHasNonFeaturedFilters] = useState<boolean>(_hasNonFeaturedFilters(filters));

  useHistory({selectedFilters, setSelectedFilters, url});

  const graphQLFilters = _mapFiltersToGraphQLFilters(selectedFilters, filterConfig);

  const [getOffers, {data, loading}] = useLazyQuery(queryDocument, {variables: {
    platformName,
    filters: graphQLFilters,
    packageFilters: productFiltersToPackageFilters(graphQLFilters),
    first,
    orderBy,
  }});

  useEffect(() => {
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    if (isInitialized && (selectedFilters !== previousSelectedFilters || first !== previousFirst || orderBy !== previousOrderBy)) {
      getOffers();
    }

    if (data?.search) {
      if (data?.packageSearch && data.packageSearch.url && data.packageSearch.results.count > 0) {
        setPackageUrl(data.packageSearch.url);
      }

      setResults(data.search as Search);
      setFilters(facetsToFilters(filterConfig, data.search.facets as Facets));

      if (data.search?.url) {
        setUrl(data.search.url);
      }
    }
  }, [
    data,
    selectedFilters,
    isInitialized,
    previousSelectedFilters,
    first,
    previousFirst,
    filterConfig,
    getOffers,
    orderBy,
    previousOrderBy,
  ]);

  useEffect(() => {
    setHasFeaturedFilters(_hasFeaturedFilters(filters));
    setHasNonFeaturedFilters(_hasNonFeaturedFilters(filters));
  }, [filters]);

  return {
    packageUrl,
    filters,
    selectedFilters,
    setFilterOption: _setFilterOptionFactory(filters, selectedFilters, setSelectedFilters),
    loading,
    results,
    first,
    showMoreResults: () => setFirst(first + 10),
    resetFilters: () => {
      setFilters(facetsToFilters(filterConfig, initialResults.facets as Facets));
      setSelectedFilters(defaultFilters);
    },
    orderBy,
    setOrderBy,
    hasNonFeaturedFilters,
    hasFeaturedFilters,
  };
}

const _hasFeaturedFilters = (filters: FiltersRecord) => Object.entries(filters)
  .some(([, filter]) => {
    return filter.featured;
  });

const _hasNonFeaturedFilters = (filters: FiltersRecord) => Object.entries(filters)
  .some(([, filter]) => {
    return !filter.featured && filter.options && filter.options.length > 1;
  });

const _mapFiltersToGraphQLFilters = (selectedFilters: SelectedFilters, filterConfig: AdvisorConfig) => {
  return Object.assign(
    {},
    ...Object.entries(selectedFilters).map(([key, value]) => {
      const filter = filterConfig.filters.find(({identifier}) => key === identifier);

      if (!filter || filter.filterType === FilterType.In) {
        return {[`${key}In`]: {value: value || []}};
      }
      if (filter.filterType === FilterType.Range) {
        return {[`${key}Range`]: {lte: value[0], gte: value[1]}};
      }
      if (filter.filterType === FilterType.RangeMax) {
        return {[`${key}Range`]: {lte: value[0]}};
      }
      if (filter.filterType === FilterType.RangeMin) {
        return {[`${key}Range`]: {gte: value[0]}};
      }
    }),
  );
};

const _setFilterOptionFactory = (filters: FiltersRecord, selectedFilters: SelectedFilters, setSelectedFilters: (filters: SelectedFilters) => void) =>
  (filter: BaseFilter, option: FilterOption) => {
    const selectedFilter = selectedFilters[filter.identifier] || [];
    const updateSelectedFilters = (values: string[]) => setSelectedFilters({...selectedFilters, [filter.identifier]: values});

    if (filter.controlType === ControlType.Radio) {
      if (option.value === EMPTY_DEFAULT_IDENTIFIER) {
        updateSelectedFilters([]);
      } else if (filter.nullableOptions && selectedFilter?.includes(option.value)) {
        updateSelectedFilters(selectedFilter.filter((selectedValue: any) => selectedValue !== option.value));
      } else {
        updateSelectedFilters([option.value as string]);
      }
    } else if (selectedFilter?.includes(option.value)) {
      updateSelectedFilters(selectedFilter.filter((selectedValue: any) => selectedValue !== option.value));
    } else {
      updateSelectedFilters([...selectedFilter || [], option.value]);
    }
  };
