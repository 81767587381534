import React from 'react';

import {BaseFilter, ControlType, EMPTY_DEFAULT_IDENTIFIER, FilterOption} from '../../advisor-config/utils';
import sortBy from '../../utils/sortBy';

interface FilterProps {
  filter: BaseFilter;
  handleOnChange: (filter: BaseFilter, option: FilterOption) => void;
  selectedOptions: any;
  showLabels?: boolean;
  compact?: boolean;
}

export default function Filter({filter, handleOnChange, selectedOptions, showLabels = true, compact = false}: FilterProps) {
  const {options = [], gridCols, minOptions} = filter;
  if (!selectedOptions.length && options.length < minOptions) {
    return null;
  }

  const gridColsClass = getFilterGridCols(gridCols);
  const compactClass = showLabels && compact ? 'sf-mb-2' : 'sf-mb-4';
  const showLabelsClass = showLabels ? 'sf-visible sf-h-auto' : 'sf-invisible sf-h-0 sf-opacity-0';

  const descriptionElement = filter.Description
    ? <div className="sf-text-sm sf-text-gray-400 sf-pb-4">
      <filter.Description />
    </div>
  : null;

  return (
    <div className="sf-block">
      <div className={`sf-font-bold sf-transition-[height,opacity] sf-ease-in-out sf-delay-75 sf-duration-1000 ${compactClass} ${showLabelsClass}`}>{filter.label}</div>
      {descriptionElement}
      <div className={`sf-gap-2 ${compact ? 'sf-flex' : `sf-grid ${gridColsClass}`} sf-flex-wrap`}>
        {sortBy(options, 'value').map((option) => {
          const isEmptyDefault = filter.controlType === ControlType.Radio && selectedOptions.length === 0 && option.value === EMPTY_DEFAULT_IDENTIFIER;
          const optionSelected = isEmptyDefault ? true : selectedOptions.includes(option.value);
          const optionIdentifier = `${filter.identifier}-${option.value}${compact && '-compact'}`;
          const optionElement = filter.Option ? <filter.Option option={option} compact={compact} /> : option.label;
          const selectedClass = optionSelected ? 'sf-border-primary sf-text-black' : 'sf-relative sf-border-gray-200';
          const compactClass = compact ? 'sf-p-3' : 'sf-h-16 sf-min-w-[100px]';

          return (
            <label
              key={optionIdentifier}
              htmlFor={optionIdentifier}
              className={`${selectedClass} ${compactClass} sf-flex sf-text-center sf-items-center sf-justify-center sf-text-sm sf-bg-white sf-border sf-rounded hover:sf-border-primary hover:sf-bg-gray-100 hover:sf-text-black sf-cursor-pointer sf-text-gray-400`}
            >
            {optionElement}
            <a href={`/${option.url || ''}`} onClick={(event) => event.preventDefault()}>
              <input
                id={optionIdentifier}
                type={filter.controlType}
                value={option.value as string | number}
                name={filter.identifier}
                checked={optionSelected}
                className="sf-border-none sf-absolute sf-opacity-0 sf-cursor-pointer"
                onChange={(event) => {
                  event.preventDefault();
                  handleOnChange(filter, option);
                }}
              />
            </a>
          </label>
          );
        })}
      </div>
    </div>
  );
}

const getFilterGridCols = (gridCols: number) => ({
  /* eslint-disable @typescript-eslint/naming-convention */
  1: 'sf-grid-cols-1',
  2: 'sf-grid-cols-2',
  3: 'sf-grid-cols-3',
  /* eslint-enable @typescript-eslint/naming-convention */
}[gridCols]);
