import {useEffect, useRef} from 'react';

/**
 * Hook to store the previous value. Useful for
 * when you want to compare the current value
 * with the previous value.
 */
export default function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
