import {useState} from 'react';

/**
 * Hook to easily toggle a boolean value
 */
export default function useToggle(defaultValue = false) {
  const [booleanValue, setBooleanValue] = useState(defaultValue);
  const toggle = () => setBooleanValue(!booleanValue);
  return [
    booleanValue,
    toggle,
  ] as const;
}
