import {useEffect, useState} from 'react';

import {SelectedFilters} from '../types/SelectedFilters';

interface UseHistoryParams {
  selectedFilters: SelectedFilters;
  setSelectedFilters: (filters: SelectedFilters) => void;
  url: string;
}

export default function useHistory({selectedFilters, setSelectedFilters, url}: UseHistoryParams) {
  const [isPopState, setIsPopState] = useState(false);

  useEffect(() => {
    if (typeof window !== undefined) {
      window.history.replaceState({filters: selectedFilters}, '', window.location.href);
      window.onpopstate = (popstate) => {
        if (popstate.state.filters) {
          setIsPopState(true);
          setSelectedFilters(popstate.state.filters);
        }
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (url && !isPopState) {
      window.history.pushState({filters: selectedFilters}, '', `/${url}`);
    }
    setIsPopState(false);
  }, [url]); // eslint-disable-line react-hooks/exhaustive-deps
}
